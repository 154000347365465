import axios from "axios";
import { appConfigs } from "../../../../../_social_justice/_helpers";

// CREATE =>  POST: add a new periodBank to the server
export function createPeriodBank(periodBank) {
  return axios.post(appConfigs.api_url + 'period-banks/create', { periodBank });
}

// READ
export function getAllPeriodBanks() {
  return axios.post(appConfigs.api_url + 'period-banks');
}

export function getPeriodBankById(periodBankId) {
  return axios.post(`${appConfigs.api_url}period-banks/get`, { id: periodBankId })
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findPeriodBanks(queryParams) {
  return axios.post(`${appConfigs.api_url}period-banks`, { queryParams });
}

// UPDATE => POST: update the periodBank on the server
export function updatePeriodBank(periodBank) {
  return axios.post(`${appConfigs.api_url}period-banks/update`, { periodBank });
}

// UPDATE Status
export function updateStatusForPeriodBanks(ids, status) {
  return axios.post(`${appConfigs.api_url}/updateStatusForPeriodBanks`, {
    ids,
    status
  });
}

// DELETE => delete the periodBank from the server
export function deletePeriodBank(periodBankId) {
  return axios.post(`${appConfigs.api_url}period-banks/delete`, { id: periodBankId });
}

// DELETE PeriodBanks by ids
export function deletePeriodBanks(ids) {
  return axios.post(`${appConfigs.api_url}period-banks/delete-period-banks`, { periodBank_ids: ids });
}

import * as requestFromServer from "./reportedCasesCrud";
import { reportedCasesSlice, callTypes } from "./reportedCasesSlice";
import * as auth from "../../../Auth/_redux/authRedux";

const { actions } = reportedCasesSlice;

export const fetchReportedCases = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findReportedCases(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.reportedCasesFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Reported Cases";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchReportedCase = id => dispatch => {
  if (!id) {
    return dispatch(actions.reportedCaseFetched({ reportedCaseForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getReportedCaseById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const reportedCase = response.data.reportedCase;
      dispatch(actions.reportedCaseFetched({ reportedCaseForEdit: reportedCase }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Reported Case";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteReportedCase = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteReportedCase(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.reportedCaseDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Reported Case";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createReportedCase = reportedCaseForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createReportedCase(reportedCaseForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { reportedCase } = response.data;
      dispatch(actions.reportedCaseCreated({ reportedCase }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Reported Case";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateReportedCase = reportedCase => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateReportedCase(reportedCase)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.reportedCaseUpdated({ reportedCase }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Reported Case";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//updateAnswer
export const updateAnswer = reportedCase => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateAnswer(reportedCase)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const answeredCase = response.data.reportedCase;
      dispatch(actions.reportedCaseAnswered({ reportedCase: answeredCase}));
    })
    .catch(error => {
      error.clientMessage = "Can't update Reported Case";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateReportedCasesStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForReportedCases(ids, status)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.reportedCasesStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update reportedCases status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteReportedCases = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteReportedCases(ids)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.reportedCasesDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Reported Cases";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
import axios from "axios";
import { appConfigs } from "../../../../../_social_justice/_helpers";

// CREATE =>  POST: add a new role to the server
export function createRole(role) {
  return axios.post(appConfigs.api_url + 'roles/create-role', { role });
}

// READ
export function getAllRoles() {
  return axios.get(appConfigs.api_url + 'roles');
}

export function getRoleById(roleId) {
  return axios.post(`${appConfigs.api_url}roles/get-role-by-id`, { id: roleId })
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findRoles(queryParams) {
  return axios.post(`${appConfigs.api_url}roles/find-roles`, { queryParams });
}

// UPDATE => POST: update the role on the server
export function updateRole(role) {
  return axios.post(`${appConfigs.api_url}roles/update-role`, { role });
}

// UPDATE Status
export function updateStatusForRoles(ids, status) {
  return axios.post(`${appConfigs.api_url}/update-status-for-roles`, {
    ids,
    status
  });
}

// DELETE => delete the role from the server
export function deleteRole(roleId) {
  return axios.post(`${appConfigs.api_url}roles/delete-role`, { role_id: roleId });
}

// DELETE Roles by ids
export function deleteRoles(ids) {
  return axios.post(`${appConfigs.api_url}roles/delete-roles`, { role_ids: ids });
}

import {createSlice} from "@reduxjs/toolkit";

const initialSocialEconomicRightsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  socialEconomicRightForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const socialEconomicRightsSlice = createSlice({
  name: "socialEconomicRights",
  initialState: initialSocialEconomicRightsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getSocialEconomicRightById
    socialEconomicRightFetched: (state, action) => {
      state.actionsLoading = false;
      state.socialEconomicRightForEdit = action.payload.socialEconomicRightForEdit;
      state.error = null;
    },
    // findSocialEconomicRights
    socialEconomicRightsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createSocialEconomicRight
    socialEconomicRightCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      if(action.payload.socialEconomicRight !== null){
        var usr = action.payload.socialEconomicRight;
        state.entities.push(usr);
      }
    },
    // updateSocialEconomicRight
    socialEconomicRightUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.socialEconomicRight.id) {
          return action.payload.socialEconomicRight;
        }
        return entity;
      });
    },
    // deleteSocialEconomicRight
    socialEconomicRightDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteSocialEconomicRights
    socialEconomicRightsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // socialEconomicRightsUpdateState
    socialEconomicRightsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});

import React, { useEffect } from "react";//
import { useDispatch, shallowEqual, useSelector } from "react-redux";
// import * as actions from "../modules/Transfers/_redux/transactions/transactionsActions";
import * as reportedCaseActions from "../modules/Settings/_redux/reportedCases/reportedCasesActions";
import * as rapidResponseActions from "../modules/Settings/_redux/rapidResponses/rapidResponsesActions";
//import * as questionAnswerActions from "../modules/Settings/_redux/questionAnswers/questionAnswersActions";
import * as hrdMaterialActions from "../modules/Settings/_redux/hrdMaterials/hrdMaterialsActions";
import * as periodBankActions from "../modules/Settings/_redux/periodBanks/periodBanksActions";
import * as treeNurseryBankActions from "../modules/Settings/_redux/treeNurseryBanks/treeNurseryBanksActions";
import * as naturalJusticeMaterialActions from "../modules/Settings/_redux/naturalJusticeMaterials/naturalJusticeMaterialsActions";
import * as socialEconomicRightActions from "../modules/Settings/_redux/socialEconomicRights/socialEconomicRightsActions";
import * as legalQuestionActions from "../modules/Settings/_redux/legalQuestions/legalQuestionsActions";
import * as lawMaterialActions from "../modules/Settings/_redux/lawMaterials/lawMaterialsActions";

import { Alert } from "react-bootstrap";
import {TilesWidget11 } from "../../_social_justice/_partials/widgets";

export function DashboardPage() {
    

    const { user_id,first_name,totalHRDMaterials,totalLegalQuestions,totalLawMaterials,totalNaturalJusticeMaterials,totalPeriodBanks,totalTreeNurseryBanks,totalRapidResponses,totalReportedCases,totalSocialEconomicRights } = useSelector( //totalQuestionAnswers
        (state) => ({
            user_id: state.auth.user.id || "",
            first_name: state.auth.user.first_name || "",
         
            totalReportedCases : state.reportedCases.totalCount,
            totalRapidResponses : state.rapidResponses.totalCount,
            totalQuestionAnswers : state.questionAnswers.totalCount,

            totalHRDMaterials : state.hrdMaterials.totalCount,
            totalPeriodBanks : state.periodBanks.totalCount,
            totalTreeNurseryBanks : state.treeNurseryBanks.totalCount,
            totalNaturalJusticeMaterials : state.naturalJusticeMaterials.totalCount,

            totalSocialEconomicRights : state.socialEconomicRights.totalCount,
            //totalLegalQuestions : state.legalQuestions.totalCount,
            totalLawMaterials : state.lawMaterials.totalCount,

        }),
        shallowEqual
    );

    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(reportedCaseActions.fetchReportedCases());
        dispatch(rapidResponseActions.fetchRapidResponses());
       //dispatch(questionAnswerActions.fetchQuestionAnswers());
        dispatch(hrdMaterialActions.fetchHRDMaterials());
        dispatch(periodBankActions.fetchPeriodBanks());
        dispatch(treeNurseryBankActions.fetchTreeNurseryBanks());
        dispatch(naturalJusticeMaterialActions.fetchNaturalJusticeMaterials());
        dispatch(socialEconomicRightActions.fetchSocialEconomicRights());
        dispatch(legalQuestionActions.fetchLegalQuestions());
        dispatch(lawMaterialActions.fetchLawMaterials());


        // eslint-disable-next-line 
    }, [dispatch, user_id]);

    //function to display a HTML grid of 3 by two

    const grid = (widget1, widget2, widget3, widget4, widget5, widget6, widget7, widget8, widget9) => {
        return (
            <div className="row">
                <div className="col-xl-4">
                    {widget1}
                </div>
                <div className="col-xl-4">
                    {widget2}
                </div>
                <div className="col-xl-4">
                    {widget3}
                </div>
                <div className="col-xl-4">
                    {widget4}
                </div>
                <div className="col-xl-4">
                    {widget5}
                </div>
                <div className="col-xl-4">
                    {widget6}
                </div>

                <div className="col-xl-4">
                    {widget7}
                </div>
                <div className="col-xl-4">
                    {widget8}
                </div>
                <div className="col-xl-4">
                    {widget9}
                </div>
            </div>
        )
    }


    return (<>
        <div className="row">
            <div className="col-xl-12">
                <div className="card card-custom gutter-b card-stretch">
                    <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                            <h2>Social Justice Admin</h2>
                            <Alert variant="primary"> Welcome {first_name}!</Alert>
                        </div>
                    </div>
                </div>
            </div>
    
            {grid(
                <TilesWidget11 svgIcon="media/svg/icons/Code/Warning-1-circle.svg" className="card-stretch gutter-b" baseColor="danger" value={totalReportedCases} label="Total Reported Cases" />,
                <TilesWidget11 svgIcon="media/svg/icons/Clothes/Briefcase.svg" className="card-stretch gutter-b" baseColor="danger" value={totalLegalQuestions} label="Total Legal Questions" />,
                <TilesWidget11 svgIcon="media/svg/icons/Home/Earth.svg" className="card-stretch gutter-b" baseColor="info" value={totalTreeNurseryBanks} label="Total Tree Nurseries" />,
                // <TilesWidget11 svgIcon="media/svg/icons/Code/Question-circle.svg" className="card-stretch gutter-b" baseColor="danger" value={totalQuestionAnswers} label="Total Questions" />,

                <TilesWidget11 svgIcon="media/svg/icons/Communication/Flag.svg" className="card-stretch gutter-b" baseColor="info" value={totalSocialEconomicRights} label="Total Social Economic Right Materials" />,
                <TilesWidget11 svgIcon="media/svg/icons/Devices/Wi-fi.svg" className="card-stretch gutter-b" baseColor="info" value={totalRapidResponses} label="Total Rapid Responses" />,
                <TilesWidget11 svgIcon="media/svg/icons/Clothes/Dress.svg" className="card-stretch gutter-b" baseColor="info" value={totalPeriodBanks} label="Total Period Banks" />,
                
                <TilesWidget11 svgIcon="media/svg/icons/Communication/Group-chat.svg" className="card-stretch gutter-b" baseColor="dark" value={totalHRDMaterials} label="Total HRD Materials" />,
                <TilesWidget11 svgIcon="media/svg/icons/Communication/Shield-user.svg" className="card-stretch gutter-b" baseColor="dark" value={totalLawMaterials} label="Total Law Materials" />,
                <TilesWidget11 svgIcon="media/svg/icons/Communication/Safe-chat.svg" className="card-stretch gutter-b" baseColor="dark" value={totalNaturalJusticeMaterials} label="Total Natural Justice Materials" />,

                
            )}
        </div>
    </>);
}

import * as requestFromServer from "./naturalJusticeMaterialsCrud";
import { naturalJusticeMaterialsSlice, callTypes } from "./naturalJusticeMaterialsSlice";
import * as auth from "../../../Auth/_redux/authRedux";

const { actions } = naturalJusticeMaterialsSlice;

export const fetchNaturalJusticeMaterials = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findNaturalJusticeMaterials(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.naturalJusticeMaterialsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find naturalJusticeMaterials";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchNaturalJusticeMaterial = id => dispatch => {
  if (!id) {
    return dispatch(actions.naturalJusticeMaterialFetched({ naturalJusticeMaterialForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getNaturalJusticeMaterialById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const naturalJusticeMaterial = response.data.naturalJusticeMaterial;
      dispatch(actions.naturalJusticeMaterialFetched({ naturalJusticeMaterialForEdit: naturalJusticeMaterial }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Natural Justice Material";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteNaturalJusticeMaterial = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteNaturalJusticeMaterial(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.naturalJusticeMaterialDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Natural Justice Material";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createNaturalJusticeMaterial = naturalJusticeMaterialForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createNaturalJusticeMaterial(naturalJusticeMaterialForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { naturalJusticeMaterial } = response.data;
      dispatch(actions.naturalJusticeMaterialCreated({ naturalJusticeMaterial }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Natural Justice Material";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateNaturalJusticeMaterial = naturalJusticeMaterial => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateNaturalJusticeMaterial(naturalJusticeMaterial)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.naturalJusticeMaterialUpdated({ naturalJusticeMaterial }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Natural Justice Material";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateNaturalJusticeMaterialsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForNaturalJusticeMaterials(ids, status)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.naturalJusticeMaterialsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update naturalJusticeMaterials status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteNaturalJusticeMaterials = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteNaturalJusticeMaterials(ids)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.naturalJusticeMaterialsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete naturalJusticeMaterials";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
import axios from "axios";
import { appConfigs } from "../../../../../_social_justice/_helpers";

// CREATE =>  POST: add a new treeNurseryBank to the server
export function createTreeNurseryBank(treeNurseryBank) {
  return axios.post(appConfigs.api_url + 'tree-nursery-banks/create', { treeNurseryBank });
}

// READ
export function getAllTreeNurseryBanks() {
  return axios.post(appConfigs.api_url + 'tree-nursery-banks');
}

export function getTreeNurseryBankById(treeNurseryBankId) {
  return axios.post(`${appConfigs.api_url}tree-nursery-banks/get`, { id: treeNurseryBankId })
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findTreeNurseryBanks(queryParams) {
  return axios.post(`${appConfigs.api_url}tree-nursery-banks`, { queryParams });
}

// UPDATE => POST: update the treeNurseryBank on the server
export function updateTreeNurseryBank(treeNurseryBank) {
  return axios.post(`${appConfigs.api_url}tree-nursery-banks/update`, { treeNurseryBank });
}

// UPDATE Status
export function updateStatusForTreeNurseryBanks(ids, status) {
  return axios.post(`${appConfigs.api_url}/updateStatusForTreeNurseryBanks`, {
    ids,
    status
  });
}

// DELETE => delete the treeNurseryBank from the server
export function deleteTreeNurseryBank(treeNurseryBankId) {
  return axios.post(`${appConfigs.api_url}tree-nursery-banks/delete`, { id: treeNurseryBankId });
}

// DELETE TreeNurseryBanks by ids
export function deleteTreeNurseryBanks(ids) {
  return axios.post(`${appConfigs.api_url}tree-nursery-banks/delete-tree-nursery-banks`, { treeNurseryBank_ids: ids });
}

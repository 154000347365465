import axios from "axios";
import { appConfigs } from "../../../../../_social_justice/_helpers";

// CREATE =>  POST: add a new naturalJusticeMaterial to the server
export function createNaturalJusticeMaterial(naturalJusticeMaterial) {
  return axios.post(appConfigs.api_url + 'natural-justice-materials/create', { naturalJusticeMaterial });
}

// READ
export function getAllNaturalJusticeMaterials() {
  return axios.post(appConfigs.api_url + 'natural-justice-materials');
}

export function getNaturalJusticeMaterialById(naturalJusticeMaterialId) {
  return axios.post(`${appConfigs.api_url}natural-justice-materials/get`, { id: naturalJusticeMaterialId })
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findNaturalJusticeMaterials(queryParams) {
  return axios.post(`${appConfigs.api_url}natural-justice-materials`, { queryParams });
}

// UPDATE => POST: update the naturalJusticeMaterial on the server
export function updateNaturalJusticeMaterial(naturalJusticeMaterial) {
  return axios.post(`${appConfigs.api_url}natural-justice-materials/update`, { naturalJusticeMaterial });
}

// UPDATE Status
export function updateStatusForNaturalJusticeMaterials(ids, status) {
  return axios.post(`${appConfigs.api_url}/updateStatusForNaturalJusticeMaterials`, {
    ids,
    status
  });
}

// DELETE => delete the naturalJusticeMaterial from the server
export function deleteNaturalJusticeMaterial(naturalJusticeMaterialId) {
  return axios.post(`${appConfigs.api_url}natural-justice-materials/delete`, { id: naturalJusticeMaterialId });
}

// DELETE NaturalJusticeMaterials by ids
export function deleteNaturalJusticeMaterials(ids) {
  return axios.post(`${appConfigs.api_url}natural-justice-materials/delete-natural-justice-materials`, { naturalJusticeMaterial_ids: ids });
}

import * as requestFromServer from "./hrdMaterialsCrud";
import { hrdMaterialsSlice, callTypes } from "./hrdMaterialsSlice";
import * as auth from "../../../Auth/_redux/authRedux";

const { actions } = hrdMaterialsSlice;

export const fetchHRDMaterials = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findHRDMaterials(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.hrdMaterialsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find HRD Materials";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchHRDMaterial = id => dispatch => {
  if (!id) {
    return dispatch(actions.hrdMaterialFetched({ hrdMaterialForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getHRDMaterialById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const hrdMaterial = response.data.hrdMaterial;
      dispatch(actions.hrdMaterialFetched({ hrdMaterialForEdit: hrdMaterial }));
    })
    .catch(error => {
      error.clientMessage = "Can't find hrdMaterial";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteHRDMaterial = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteHRDMaterial(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.hrdMaterialDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete hrdMaterial";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createHRDMaterial = hrdMaterialForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createHRDMaterial(hrdMaterialForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { hrdMaterial } = response.data;
      dispatch(actions.hrdMaterialCreated({ hrdMaterial }));
    })
    .catch(error => {
      error.clientMessage = "Can't create hrdMaterial";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateHRDMaterial = hrdMaterial => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateHRDMaterial(hrdMaterial)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.hrdMaterialUpdated({ hrdMaterial }));
    })
    .catch(error => {
      error.clientMessage = "Can't update hrdMaterial";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateHRDMaterialsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForHRDMaterials(ids, status)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.hrdMaterialsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update hrdMaterials status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteHRDMaterials = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteHRDMaterials(ids)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.hrdMaterialsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete HRD Materials";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
import axios from "axios";
import { appConfigs } from "../../../../../_social_justice/_helpers";

// CREATE =>  POST: add a new socialEconomicRight to the server
export function createSocialEconomicRight(socialEconomicRight) {
  return axios.post(appConfigs.api_url + 'social-economic-rights/create', { socialEconomicRight });
}

// READ
export function getAllSocialEconomicRights() {
  return axios.post(appConfigs.api_url + 'social-economic-rights');
}

export function getSocialEconomicRightById(socialEconomicRightId) {
  return axios.post(`${appConfigs.api_url}social-economic-rights/get`, { id: socialEconomicRightId })
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSocialEconomicRights(queryParams) {
  return axios.post(`${appConfigs.api_url}social-economic-rights`, { queryParams });
}

// UPDATE => POST: update the socialEconomicRight on the server
export function updateSocialEconomicRight(socialEconomicRight) {
  return axios.post(`${appConfigs.api_url}social-economic-rights/update`, { socialEconomicRight });
}

// UPDATE Status
export function updateStatusForSocialEconomicRights(ids, status) {
  return axios.post(`${appConfigs.api_url}/updateStatusForSocialEconomicRights`, {
    ids,
    status
  });
}

// DELETE => delete the socialEconomicRight from the server
export function deleteSocialEconomicRight(socialEconomicRightId) {
  return axios.post(`${appConfigs.api_url}social-economic-rights/delete`, { id: socialEconomicRightId });
}

// DELETE SocialEconomicRights by ids
export function deleteSocialEconomicRights(ids) {
  return axios.post(`${appConfigs.api_url}social-economic-rights/delete-social-economic-rights`, { socialEconomicRight_ids: ids });
}

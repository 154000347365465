import {createSlice} from "@reduxjs/toolkit";

const initialRapidResponsesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  rapidResponseForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const rapidResponsesSlice = createSlice({
  name: "rapidResponses",
  initialState: initialRapidResponsesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getRapidResponseById
    rapidResponseFetched: (state, action) => {
      state.actionsLoading = false;
      state.rapidResponseForEdit = action.payload.rapidResponseForEdit;
      state.error = null;
    },
    // findRapidResponses
    rapidResponsesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createRapidResponse
    rapidResponseCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      if(action.payload.rapidResponse !== null){
        var usr = action.payload.rapidResponse;
        state.entities.push(usr);
      }
    },
    // updateRapidResponse
    rapidResponseUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.rapidResponse.id) {
          return action.payload.rapidResponse;
        }
        return entity;
      });
    },
    // deleteRapidResponse
    rapidResponseDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteRapidResponses
    rapidResponsesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // rapidResponsesUpdateState
    rapidResponsesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});

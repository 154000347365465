import axios from "axios";
import { appConfigs } from "../../../../../_social_justice/_helpers";

// CREATE =>  POST: add a new reportedCase to the server
export function createReportedCase(reportedCase) {
  return axios.post(appConfigs.api_url + 'reported-cases/create', { reportedCase });
}

// READ
export function getAllReportedCases() {
  return axios.post(appConfigs.api_url + 'reported-cases');
}

export function getReportedCaseById(reportedCaseId) {
  return axios.post(`${appConfigs.api_url}reported-cases/get`, { id: reportedCaseId })
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findReportedCases(queryParams) {
  return axios.post(`${appConfigs.api_url}reported-cases`, { queryParams });
}

// UPDATE => POST: update the reportedCase on the server
export function updateReportedCase(reportedCase) {
  return axios.post(`${appConfigs.api_url}reported-cases/update`, { reportedCase });
}

//answer
export function updateAnswer(reportedCase) {
  return axios.post(`${appConfigs.api_url}reported-cases/answer`, { reportedCase });
}

// UPDATE Status
export function updateStatusForReportedCases(ids, status) {
  return axios.post(`${appConfigs.api_url}/updateStatusForReportedCases`, {
    ids,
    status
  });
}

// DELETE => delete the reportedCase from the server
export function deleteReportedCase(reportedCaseId) {
  return axios.post(`${appConfigs.api_url}reported-cases/delete`, { id: reportedCaseId });
}

// DELETE ReportedCases by ids
export function deleteReportedCases(ids) {
  return axios.post(`${appConfigs.api_url}reported-cases/delete-reported-cases`, { reportedCase_ids: ids });
}

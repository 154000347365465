export const appMenus = [
    {
        title: "Management Requests",
        icon: "/media/svg/icons/Files/Selected-file.svg",
        page: "/transfers",
        tab: "tabTransfers",
        permission: "",
        section: [
            {
                //Dashboard
                title: "Dashboard",
                permission: "accessToSettingsRead",
                submenu: [
                    {
                        title: "Dashboard",
                        icon: "/media/svg/icons/Home/Flower1.svg",
                        page: "/dashboard",
                        permission: "accessToSettingsRead",
                    },
                ],
            },
            {
                title: "Manage Responses",
                permission: "accessToSettingsRead",
                submenu: [
                    {
                        title: "Reported Cases",
                        icon: "/media/svg/icons/General/Shield-check.svg",
                        page: "/reported-case-management/reported-cases",
                        permission: "accessToSettingsRead",
                    },
                    // {
                    //     title: "Questions",
                    //     icon: "/media/svg/icons/General/Binocular.svg",
                    //     page: "/question-answer-management/question-answers",
                    //     permission: "accessToSettingsRead",
                    // },
                    {
                        title: "Legal Questions",
                        icon: "/media/svg/icons/General/Scale.svg",
                        page: "/legal-question-management/legal-questions",
                        permission: "accessToSettingsRead",
                    },
                ],
            },
            {
                title: "Manage Content",
                permission: "accessToSettingsRead",
                submenu: [
                    {
                        title: "Rapid Responses",
                        icon: "/media/svg/icons/General/Shield-protected.svg",
                        page: "/rapid-response-management/rapid-responses",
                        permission: "accessToSettingsRead",
                    },
                    {
                        title: "HRD Materials",
                        icon: "/media/svg/icons/General/Unlock.svg",
                        page: "/hrd-material-management/hrd-materials",
                        permission: "accessToSettingsRead",
                    },
                    {
                        title: "Law Materials",
                        icon: "/media/svg/icons/General/Scale.svg",
                        page: "/law-material-management/law-materials",
                        permission: "accessToSettingsRead",
                    },
                    {
                        title: "Social Economic Rights",
                        icon: "/media/svg/icons/Home/Earth.svg",
                        page: "/social-economic-right-management/social-economic-rights",
                        permission: "accessToSettingsRead",
                    },
                    {
                        title: "Natural Justice Materials",
                        icon: "/media/svg/icons/General/Heart.svg",
                        page: "/natural-justice-material-management/natural-justice-materials",
                        permission: "accessToSettingsRead",
                    },
                    {
                        title: "Period Banks",
                        icon: "/media/svg/icons/General/Sad.svg",
                        page: "/period-bank-management/period-banks",
                        permission: "accessToSettingsRead",
                    },

                    {
                        title: "Tree Nursery Banks",
                        icon: "/media/svg/icons/Home/Earth.svg",
                        page: "/tree-nursery-bank-management/tree-nursery-banks",
                        permission: "accessToSettingsRead",
                    },
                    {
                        title: "Justice Centres",
                        icon: "/media/svg/icons/General/Settings-1.svg",
                        page: "/justice-centre-management/justice-centres",
                        permission: "accessToSettingsRead",
                    },
                ],
            },
            {
                title: "Reports",
                permission: "accessToReportsAdminRead",
                submenu: [
                    {
                        title: "Transactions",
                        icon: "/media/svg/icons/Files/Folder-cloud.svg",
                        page: "/reports/transactions",
                        permission: "accessToReportsAdminRead",
                    },
                    {
                        title: "Agents",
                        icon: "/media/svg/icons/Files/Folder-cloud.svg",
                        page: "/reports/agents",
                        permission: "accessToReportsAdminRead",
                    },
                    {
                        title: "Users",
                        icon: "/media/svg/icons/Files/Folder-cloud.svg",
                        page: "/reports/users",
                        permission: "accessToReportsAdminRead",
                    },
                ],
            },
        ],
    },
    {
        title: "User Management",
        icon: "/media/svg/icons/Communication/Group.svg",
        page: "/user-management",
        tab: "tabUsers",
        permission: "accessToUsersRead",
        section: [
            {
                title: "User Management",
                permission: "accessToUsersRead",
                submenu: [
                    {
                        title: "Manage Users",
                        icon: "/media/svg/icons/Communication/Group.svg",
                        page: "/user-management/users",
                        permission: "accessToUsersRead",
                    },
                    {
                        title: "Manage Roles",
                        icon: "/media/svg/icons/Code/Compiling.svg",
                        page: "/user-management/roles",
                        permission: "accessToRolesRead",
                    },
                ],
            },
        ],
    },
    {
        title: "Settings",
        icon: "/media/svg/icons/General/Settings-1.svg",
        page: "/settings",
        tab: "tabSettings",
        permission: "accessToSettingsRead",
        section : [
            {
                title: "Settings",
                permission: "accessToSettingsRead",
                submenu: [
                    {
                        title: "View Settings",
                        icon: "/media/svg/icons/General/Settings-1.svg",
                        page: "/settings/view",
                        permission: "accessToSettingsRead",
                    },
                    {
                        title: "Update Settings",
                        icon: "/media/svg/icons/General/Settings-1.svg",
                        page: "/settings/update",
                        permission: "accessToSettingsRead",
                    },
                ],
            },
        ],
    }
];
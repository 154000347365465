/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react"; //useEffect,
import objectPath from "object-path";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl, appMenus } from "../../../_helpers";
import { AsideMenu } from "./aside-menu/AsideMenu";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuickUserToggler";
import { Brand } from "../brand/Brand";
import { KTUtil } from "./../../../_assets/js/components/util";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import * as auth from "../../../../app/modules/Auth/_redux/authRedux";
import * as roleActions from "../../../../app/modules/UserManagement/_redux/roles/rolesActions";

export function Aside() {

  


  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses("aside", true),
      asideSecondaryDisplay: objectPath.get(
        uiService.config,
        "aside.secondary.display"
      ),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      extrasSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      extrasNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      extrasQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      extrasQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      extrasLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      extrasUserDisplay: objectPath.get(
        uiService.config,
        "extras.user.display"
      ),
    };
  }, [uiService]);

  const tabs = {
    tabTransfers: "kt_aside_tab_transfers",
    tabUsers: "kt_aside_tab_users",
    tabAgents: "kt_aside_tab_licensing",
    tabSettings: "kt_aside_tab_settings",
    tabReports: "kt_aside_tab_reports",
  };

  const history = useHistory();
  const curr_url = useLocation().pathname;
  const activeUrls = [
    { tab: "tabTransfers", url_segment: "/dashboard" },
    { tab: "tabUsers", url_segment: "/user-management/" },
    { tab: "tabAgents", url_segment: "/agent-management/" },
    { tab: "tabSettings", url_segment: "/settings/" },
    { tab: "tabReports", url_segment: "/reports/" },
  ];
  let _activeTab = "tabTransfers";
  activeUrls.forEach((s) => {
    if (curr_url.includes(s.url_segment)) {
      _activeTab = s.tab
    }
  });

  const [activeTab, setActiveTab] = useState(tabs[_activeTab]);

  const handleTabChange = (id) => {
    setActiveTab(id);

    const asideWorkspace = KTUtil.find(
      document.getElementById("kt_aside"),
      ".aside-secondary .aside-workspace"
    );
    if (asideWorkspace) {
      KTUtil.scrollUpdate(asideWorkspace);
    }
  };

  

  const { user_id, allRoles } = useSelector( // actionsLoading, businesses, user_id, individual_applications
    (state) => ({
      actionsLoading: state.roles.actionsLoading,
      allRoles: state.roles.entities || [],
      user_id: state.auth.user.id,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(roleActions.fetchRoles());
  }, [user_id, dispatch]);


  const curr_path = useLocation().pathname;

  // ====== Get permissions ======
  const user_roles = useSelector((state) => state.auth.user.roles, shallowEqual);
  //const user_permission_ids = useSelector((state) => state.auth.user.permissions, shallowEqual);
  const userRolePermission = allRoles.filter((role) => user_roles.includes(role.id));
  const user_permissions =  auth.getUserPermissions(userRolePermission);

  const renderTabs = Object.entries(tabs).map(([key, value]) => {
    return <AsideMenu key={key} isActive={activeTab === value} toRender={value} permissions={user_permissions} />
  });

  return (
    <>
      {/* begin::Aside */}
      <div id="kt_aside" className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`} >
        {/* begin::Primary */}
        <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
          <Brand />
          {/* begin::Nav Wrapper */}
          <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
            {/* begin::Nav */}
            <ul className="list-unstyled flex-column" role="tablist">
              {appMenus.map((menu, i) => {
                return (
                  (user_permissions.includes(menu.permission) || menu.permission === "") ?
                    <li className="nav-item mb-3" data-toggle="tooltip" data-placement="rigth" data-container="body" data-boundary="window" key={'root-menu-' + i}>
                      <OverlayTrigger placement="right" overlay={<Tooltip id="latest-project">{menu.title}</Tooltip>} >
                        <span
                          className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab === tabs[menu.tab] && "active"}`}
                          data-toggle="tab"
                          data-target={`#${tabs[menu.tab]}`}
                          role="tab"
                          onClick={() => handleTabChange(tabs[menu.tab])}
                        >
                          <span className="svg-icon svg-icon-lg">
                            <SVG src={toAbsoluteUrl(menu.icon)} />
                          </span>
                        </span>
                      </OverlayTrigger>
                    </li> : ''
                )
              })}
            </ul>
            {/* end::Nav */}
          </div>
          {/* end::Nav Wrapper */}

          {/* begin::Footer */}
          <div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
            {/* begin::Aside Toggle */}
            {layoutProps.asideSecondaryDisplay &&
              layoutProps.asideSelfMinimizeToggle && (
                <>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="toggle-aside">Toggle Menu</Tooltip>}
                  >
                    <span
                      className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
                      id="kt_aside_toggle"
                    >
                      <i className="ki ki-bold-arrow-back icon-sm" />
                    </span>
                  </OverlayTrigger>
                </>
              )}
            {/* end::Aside Toggle */}

            {/* begin::Search */}
            {layoutProps.extrasSearchDisplay && (
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="toggle-search">Quick Search</Tooltip>}
              >
                <a
                  href="#"
                  className="btn btn-icon btn-clean btn-lg mb-1"
                  id="kt_quick_search_toggle"
                >
                  <span className="svg-icon svg-icon-lg">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {/* end::Search */}

            {/* begin::Notifications */}
            {/*
            {layoutProps.extrasNotificationsDisplay && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="toggle-notifications">Notifications</Tooltip>
                }
              >
                <a
                  href="#"
                  className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                  id="kt_quick_notifications_toggle"
                  data-placement="right"
                  data-container="body"
                  data-boundary="window"
                >
                  <span className="svg-icon svg-icon-lg">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Notification2.svg")} />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            */}
            {/* end::Notifications */}

            {/* begin::Notifications */}
            {/*
            {layoutProps.extrasQuickPanelDisplay && (
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="toggle-quick-panel">Notifications</Tooltip>}
              >
                <span
                  className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                  id="kt_quick_panel_toggle"
                  data-placement="right"
                  data-container="body"
                  data-boundary="window"
                >
                  <span className="svg-icon svg-icon-lg">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Notifications1.svg")} />
                  </span>
                  <span className="label label-sm label-light-primary label-rounded font-weight-bolder position-absolute top-0 right-0 mt-1 mr-1">
                    9+
                  </span>
                </span>
              </OverlayTrigger>
            )}
            */}
            {/* end::Quick Panel */}

            {/* begin::Languages*/}
            {layoutProps.extrasLanguagesDisplay && <LanguageSelectorDropdown />}
            {/* end::Languages */}

            {/* begin::User*/}
            {layoutProps.extrasUserDisplay && <QuickUserToggler />}
            {/* end::User */}
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Primary */}

        {layoutProps.asideSecondaryDisplay && (
          <>
            {/* begin::Secondary */}
            <div className="aside-secondary d-flex flex-row-fluid">
              {/* begin::Workspace */}
              <div className="aside-workspace scroll scroll-push my-2">
                <div className="tab-content">

                  {/* begin::Search Form */}
                  <div className={`p-3 px-lg-7 py-lg-5`}>
                    <form className="p-2 p-lg-3">
                      <div className="input-icon h-40px">
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid h-40px"
                          placeholder="Search anything..."
                          id="generalSearch"
                          autoComplete="off"
                        />
                        <span>
                          <span className="svg-icon svg-icon-lg">
                            <SVG
                              src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                            />
                          </span>
                        </span>
                      </div>
                    </form>
                  </div>
                  {/* end::Search Form */}

                  <div className={`px-lg-7 mx-3`}>
                    <button
                      className="btn btn-block btn-danger font-weight-bold my-2 mb-2"
                      onClick={() => history.push("/reported-case-management/reported-cases")}
                      disabled={curr_path.includes("/reported-case-management/reported-cases")}
                    >
                      <span className="svg-icon svg-icon-lg mr-2">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Shield-check.svg")} />
                      </span>
                        Review Reported Cases
                    </button>
                  </div>

                  {renderTabs}

                </div>
              </div>
              {/* end::Workspace */}
            </div>
            {/* end::Secondary */}
          </>
        )}
      </div>
      {/* end::Aside */}
    </>
  );
}

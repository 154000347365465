import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken, logout } from "./authCrud";
import { appConfigs, appPermissions } from "../../../../_social_justice/_helpers";

export const getUserPermissions = (user_roles) => {
  const _roles = [...new Set([].concat.apply([], user_roles?.map(role => role.permissions )))];
  const _user_permissions = appPermissions['permissions'].filter((elem) => _roles.some(f => elem.id === f)).map(e => e.name);
  return _user_permissions;
}

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  RefreshToken: "[Refresh Token] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
};

export const reducer = persistReducer(
  { storage, key: appConfigs.auth_key, whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.RefreshToken: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
          //undefined
        //send a logout request to the server
        const { authToken } = action.payload;
        logout(authToken);
        return { authToken , user: undefined };
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload.user;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({
    type: actionTypes.Login,
    payload: { authToken }
  }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: (authToken) => ({
    type: actionTypes.Logout,
    payload: { authToken },
  }),
  refreshToken: (authToken) => ({
    type: actionTypes.RefreshToken,
    payload: { authToken },
  }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({
    type: actionTypes.UserLoaded,
    payload: { user }
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();
    yield put(actions.fulfillUser(user));
  });
}

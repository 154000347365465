import axios from "axios";
import { appConfigs } from "../../../../_social_justice/_helpers";

export function login(email, password) {
  return axios.post(appConfigs.api_url + 'users/login', { email, password });
}

export function logout(authToken) {

  console.log("logout called", authToken );
  //add headers for token
  if(authToken){
    return axios.post(appConfigs.api_url + 'users/logout', { }, { headers: { Authorization: `Bearer ${authToken}` } });
  } else {
    return axios.post(appConfigs.api_url + 'users/logout', { });
  }
}

export function register(email, fullname, firstname, middlename, lastname, password) {
  return axios.post(appConfigs.api_url + 'users/signup', { email, fullname, firstname, middlename, lastname, password });
}

export function resetPassword(email) {
  return axios.post(appConfigs.api_url + 'users/reset-password', { email });
}

export function newPassword(password, reset_code) {
  return axios.post(appConfigs.api_url + 'users/new-password', { password, reset_code });
}

export function verifyEmail(code) {
  return axios.post(appConfigs.api_url + 'users/verify-email', { code });
}

export function resendVerification(email) {
  return axios.post(appConfigs.api_url + 'users/resend-verification', { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.post(appConfigs.api_url + 'users/get-user-by-token');
}

export function validatePortalToken(token) {
  return axios.post(appConfigs.api_url + 'users/validate-token', { token });
}

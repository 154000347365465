import * as requestFromServer from "./treeNurseryBanksCrud";
import { treeNurseryBanksSlice, callTypes } from "./treeNurseryBanksSlice";
import * as auth from "../../../Auth/_redux/authRedux";

const { actions } = treeNurseryBanksSlice;

export const fetchTreeNurseryBanks = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findTreeNurseryBanks(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.treeNurseryBanksFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Tree Nursery Banks";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchTreeNurseryBank = id => dispatch => {
  if (!id) {
    return dispatch(actions.treeNurseryBankFetched({ treeNurseryBankForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTreeNurseryBankById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const treeNurseryBank = response.data.treeNurseryBank;
      dispatch(actions.treeNurseryBankFetched({ treeNurseryBankForEdit: treeNurseryBank }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Tree Nursery Bank";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteTreeNurseryBank = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteTreeNurseryBank(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.treeNurseryBankDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Tree Nursery Bank";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createTreeNurseryBank = treeNurseryBankForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createTreeNurseryBank(treeNurseryBankForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { treeNurseryBank } = response.data;
      dispatch(actions.treeNurseryBankCreated({ treeNurseryBank }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Tree Nursery Bank";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateTreeNurseryBank = treeNurseryBank => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateTreeNurseryBank(treeNurseryBank)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.treeNurseryBankUpdated({ treeNurseryBank }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Tree Nursery Bank";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateTreeNurseryBanksStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForTreeNurseryBanks(ids, status)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.treeNurseryBanksStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update period banks status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteTreeNurseryBanks = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteTreeNurseryBanks(ids)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.treeNurseryBanksDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Tree Nursery Banks";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
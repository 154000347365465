export const appConfigs = {
    app_name: "Social Justice Admin",
    org_name: process.env.NODE_ENV !== "production" ? "Social Justice Admin (Dev)" : "Social Justice Admin",
    org_url: "https://social-justice.africa",
    api_url: process.env.NODE_ENV !== "production" ? "http://localhost:8855/api/" : (window.location.host === "social-justice.safariwebstudio.com" ? "https://social-justice.safariwebstudio.com:8400/api/" : "https://social-justice.africa:8400/api/"),
    portal_api_url: "https://social-justice.safariwebstudio.com:8400/api/",
    
    auth_key: process.env.NODE_ENV !== "production" ? "XNAgZSnoEsf55nlbmDIJ" : "rK32640byHUTixKE7ge1",
    uploads_url: process.env.NODE_ENV !== "production" ? "http://localhost:8855/u0veDcqIsErQVKXuXeM3/" : (window.location.host === "social-justice.safariwebstudio.com" ? "https://social-justice.safariwebstudio.com:8400/u0veDcqIsErQVKXuXeM3/" : "https://social-justice.africa:8400/u0veDcqIsErQVKXuXeM3/"),
    recaptcha_site_key: "6LfdYeQZAAAAAPijoDtW-p4RLdUZBnc0brmarW5M",
};
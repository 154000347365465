import * as requestFromServer from "./periodBanksCrud";
import { periodBanksSlice, callTypes } from "./periodBanksSlice";
import * as auth from "../../../Auth/_redux/authRedux";

const { actions } = periodBanksSlice;

export const fetchPeriodBanks = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPeriodBanks(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.periodBanksFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Period Banks";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchPeriodBank = id => dispatch => {
  if (!id) {
    return dispatch(actions.periodBankFetched({ periodBankForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getPeriodBankById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const periodBank = response.data.periodBank;
      dispatch(actions.periodBankFetched({ periodBankForEdit: periodBank }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Period Bank";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deletePeriodBank = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deletePeriodBank(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.periodBankDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Period Bank";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createPeriodBank = periodBankForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createPeriodBank(periodBankForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { periodBank } = response.data;
      dispatch(actions.periodBankCreated({ periodBank }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Period Bank";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updatePeriodBank = periodBank => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updatePeriodBank(periodBank)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.periodBankUpdated({ periodBank }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Period Bank";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updatePeriodBanksStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForPeriodBanks(ids, status)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.periodBanksStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update period banks status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deletePeriodBanks = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deletePeriodBanks(ids)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.periodBanksDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Period Banks";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
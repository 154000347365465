import * as requestFromServer from "./socialEconomicRightsCrud";
import { socialEconomicRightsSlice, callTypes } from "./socialEconomicRightsSlice";
import * as auth from "../../../Auth/_redux/authRedux";

const { actions } = socialEconomicRightsSlice;

export const fetchSocialEconomicRights = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSocialEconomicRights(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.socialEconomicRightsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Social Economic Rights";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSocialEconomicRight = id => dispatch => {
  if (!id) {
    return dispatch(actions.socialEconomicRightFetched({ socialEconomicRightForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSocialEconomicRightById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const socialEconomicRight = response.data.socialEconomicRight;
      dispatch(actions.socialEconomicRightFetched({ socialEconomicRightForEdit: socialEconomicRight }));
    })
    .catch(error => {
      error.clientMessage = "Can't find socialEconomicRight";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSocialEconomicRight = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSocialEconomicRight(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.socialEconomicRightDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete socialEconomicRight";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSocialEconomicRight = socialEconomicRightForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createSocialEconomicRight(socialEconomicRightForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { socialEconomicRight } = response.data;
      dispatch(actions.socialEconomicRightCreated({ socialEconomicRight }));
    })
    .catch(error => {
      error.clientMessage = "Can't create socialEconomicRight";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSocialEconomicRight = socialEconomicRight => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSocialEconomicRight(socialEconomicRight)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.socialEconomicRightUpdated({ socialEconomicRight }));
    })
    .catch(error => {
      error.clientMessage = "Can't update socialEconomicRight";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSocialEconomicRightsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForSocialEconomicRights(ids, status)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.socialEconomicRightsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update socialEconomicRights status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSocialEconomicRights = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSocialEconomicRights(ids)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.socialEconomicRightsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Social Economic Rights";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
import axios from "axios";
import { appConfigs } from "../../../../../_social_justice/_helpers";

// CREATE =>  POST: add a new rapidResponse to the server
export function createRapidResponse(rapidResponse) {
  return axios.post(appConfigs.api_url + 'rapid-responses/create', { rapidResponse });
}

// READ
export function getAllRapidResponses() {
  return axios.post(appConfigs.api_url + 'rapid-responses');
}

export function getRapidResponseById(rapidResponseId) {
  return axios.post(`${appConfigs.api_url}rapid-responses/get`, { id: rapidResponseId })
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findRapidResponses(queryParams) {
  return axios.post(`${appConfigs.api_url}rapid-responses`, { queryParams });
}

// UPDATE => POST: update the rapidResponse on the server
export function updateRapidResponse(rapidResponse) {
  return axios.post(`${appConfigs.api_url}rapid-responses/update`, { rapidResponse });
}

// UPDATE Status
export function updateStatusForRapidResponses(ids, status) {
  return axios.post(`${appConfigs.api_url}/updateStatusForRapidResponses`, {
    ids,
    status
  });
}

// DELETE => delete the rapidResponse from the server
export function deleteRapidResponse(rapidResponseId) {
  return axios.post(`${appConfigs.api_url}rapid-responses/delete`, { id: rapidResponseId });
}

// DELETE RapidResponses by ids
export function deleteRapidResponses(ids) {
  return axios.post(`${appConfigs.api_url}rapid-responses/delete-rapid-responses`, { rapidResponse_ids: ids });
}

const generateApiKey = () => {
  const dateToday = new Date();
  const dateNow = new Date(dateToday.getFullYear(), dateToday.getMonth(), dateToday.getDate());
  const validApiKey = '3264bwb-' + dateNow.getTime();
  
  // Function to reverse a string
  const reverseString = (str) => str.split('').reverse().join('');
  
  // Reverse the API key
  return reverseString(validApiKey);
};

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      const apiKey = generateApiKey();

      if(apiKey){
        config.headers['x-api-key'] = apiKey;

        console.log('API key: '+apiKey);
      }

      



      return config;
    },
    err => Promise.reject(err)
  );
}




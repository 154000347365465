import axios from "axios";
import { appConfigs } from "../../../../../_social_justice/_helpers";

// CREATE =>  POST: add a new hrdMaterial to the server
export function createHRDMaterial(hrdMaterial) {
  return axios.post(appConfigs.api_url + 'hrd-materials/create', { hrdMaterial });
}

// READ
export function getAllHRDMaterials() {
  return axios.post(appConfigs.api_url + 'hrd-materials');
}

export function getHRDMaterialById(hrdMaterialId) {
  return axios.post(`${appConfigs.api_url}hrd-materials/get`, { id: hrdMaterialId })
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findHRDMaterials(queryParams) {
  return axios.post(`${appConfigs.api_url}hrd-materials`, { queryParams });
}

// UPDATE => POST: update the hrdMaterial on the server
export function updateHRDMaterial(hrdMaterial) {
  return axios.post(`${appConfigs.api_url}hrd-materials/update`, { hrdMaterial });
}

// UPDATE Status
export function updateStatusForHRDMaterials(ids, status) {
  return axios.post(`${appConfigs.api_url}/updateStatusForHRDMaterials`, {
    ids,
    status
  });
}

// DELETE => delete the hrdMaterial from the server
export function deleteHRDMaterial(hrdMaterialId) {
  return axios.post(`${appConfigs.api_url}hrd-materials/delete`, { id: hrdMaterialId });
}

// DELETE HRDMaterials by ids
export function deleteHRDMaterials(ids) {
  return axios.post(`${appConfigs.api_url}hrd-materials/delete-hrd-materials`, { hrdMaterial_ids: ids });
}

import * as requestFromServer from "./legalQuestionsCrud";
import { legalQuestionsSlice, callTypes } from "./legalQuestionsSlice";
import * as auth from "../../../Auth/_redux/authRedux";

const { actions } = legalQuestionsSlice;

export const fetchLegalQuestions = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findLegalQuestions(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.legalQuestionsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Legal Questions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchLegalQuestion = id => dispatch => {
  if (!id) {
    return dispatch(actions.legalQuestionFetched({ legalQuestionForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getLegalQuestionById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const legalQuestion = response.data.legalQuestion;
      dispatch(actions.legalQuestionFetched({ legalQuestionForEdit: legalQuestion }));
    })
    .catch(error => {
      error.clientMessage = "Can't find legal question";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteLegalQuestion = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteLegalQuestion(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.legalQuestionDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete legal question";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createLegalQuestion = legalQuestionForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createLegalQuestion(legalQuestionForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { legalQuestion } = response.data;
      dispatch(actions.legalQuestionCreated({ legalQuestion }));
    })
    .catch(error => {
      error.clientMessage = "Can't create legal question";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateLegalQuestion = legalQuestion => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateLegalQuestion(legalQuestion)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.legalQuestionUpdated({ legalQuestion }));
    })
    .catch(error => {
      error.clientMessage = "Can't update legal question";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//answerLegalQuestion
export const answerLegalQuestion = legalQuestion => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .answerLegalQuestion(legalQuestion)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const question = response.data.legalQuestion;
      dispatch(actions.legalQuestionAnswered({ legalQuestion: question }));
    })
    .catch(error => {
      error.clientMessage = "Can't answer legal question";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateLegalQuestionsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForLegalQuestions(ids, status)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.legalQuestionsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update legalQuestions status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteLegalQuestions = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteLegalQuestions(ids)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.legalQuestionsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Legal Questions";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
import * as requestFromServer from "./lawMaterialsCrud";
import { lawMaterialsSlice, callTypes } from "./lawMaterialsSlice";
import * as auth from "../../../Auth/_redux/authRedux";

const { actions } = lawMaterialsSlice;

export const fetchLawMaterials = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findLawMaterials(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.lawMaterialsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Law Materials";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchLawMaterial = id => dispatch => {
  if (!id) {
    return dispatch(actions.lawMaterialFetched({ lawMaterialForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getLawMaterialById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const lawMaterial = response.data.lawMaterial;
      dispatch(actions.lawMaterialFetched({ lawMaterialForEdit: lawMaterial }));
    })
    .catch(error => {
      error.clientMessage = "Can't find lawMaterial";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteLawMaterial = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteLawMaterial(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.lawMaterialDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete lawMaterial";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createLawMaterial = lawMaterialForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createLawMaterial(lawMaterialForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { lawMaterial } = response.data;
      dispatch(actions.lawMaterialCreated({ lawMaterial }));
    })
    .catch(error => {
      error.clientMessage = "Can't create law Material";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateLawMaterial = lawMaterialForUpdate => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateLawMaterial(lawMaterialForUpdate)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { lawMaterial } = response.data;
      dispatch(actions.lawMaterialUpdated({ lawMaterial }));
    })
    .catch(error => {
      error.clientMessage = "Can't update lawMaterial";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateLawMaterialsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForLawMaterials(ids, status)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.lawMaterialsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update lawMaterials status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteLawMaterials = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteLawMaterials(ids)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.lawMaterialsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Law Materials";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
import * as requestFromServer from "./rapidResponsesCrud";
import { rapidResponsesSlice, callTypes } from "./rapidResponsesSlice";
import * as auth from "../../../Auth/_redux/authRedux";

const { actions } = rapidResponsesSlice;

export const fetchRapidResponses = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findRapidResponses(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.rapidResponsesFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Rapid Responses";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchRapidResponse = id => dispatch => {
  if (!id) {
    return dispatch(actions.rapidResponseFetched({ rapidResponseForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getRapidResponseById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const rapidResponse = response.data.rapidResponse;
      dispatch(actions.rapidResponseFetched({ rapidResponseForEdit: rapidResponse }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Rapid Response";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteRapidResponse = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteRapidResponse(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.rapidResponseDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Rapid Response";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createRapidResponse = rapidResponseForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createRapidResponse(rapidResponseForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { rapidResponse } = response.data;
      dispatch(actions.rapidResponseCreated({ rapidResponse }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Rapid Response";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateRapidResponse = rapidResponse => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateRapidResponse(rapidResponse)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.rapidResponseUpdated({ rapidResponse }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Rapid Response";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateRapidResponsesStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForRapidResponses(ids, status)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.rapidResponsesStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update rapidResponses status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteRapidResponses = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteRapidResponses(ids)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.rapidResponsesDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Rapid Responses";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl, appConfigs } from "../../../../_social_justice/_helpers";
import { ContentRoute } from "../../../../_social_justice/layout"
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./VerifyEmail";
import "../../../../_social_justice/_assets/sass/pages/login/classic/login-1.scss";
import { FormattedMessage } from "react-intl";
import { useLocation } from 'react-router-dom';
import SVG from "react-inlinesvg";

export function AuthPage() {

  const current_year = new Date().getFullYear();
  const curr_path = useLocation().pathname;
  const urlsWithoutBack = [
    "/auth/login",
    "/auth/business-options",
  ];
  const urlsWithFullLayout = [
    "/auth/business-options",
  ];

  function getHeaderBackLink() {
    if(!urlsWithoutBack.includes(curr_path)) {
      return (
        <div className="position-absolute top-0 left-0 text-right- mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center- py-5 px-10">
          <Link to="/auth/login" className="font-weight-bold ml-2" id="kt_login_signup" title="Go back to log in">
            <span className="svg-icon svg-icon-xl">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")}/>
            </span>
          </Link>
        </div>
      );
    }
  }

  function getAsideTemplate() {
    return !urlsWithFullLayout.includes(curr_path) ? 
      <div className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-2 p-lg-2" style={{backgroundImage: `url(${toAbsoluteUrl("/media/bg/social-justice-bg2.jpg")})` }}>
        <div className="d-flex flex-row-fluid flex-column justify-content-between">
          <Link to="/" className="flex-column-auto mt-5">
            <img
              alt="Logo"
              className="max-h-70px"
              src={toAbsoluteUrl("/media/logo/logo-white.png")}
            />
          </Link>
          <div className="flex-column-fluid d-flex flex-column justify-content-center">
            <h3 className="font-size-h1 mb-5 text-white">{appConfigs.app_name}</h3>
            <p className="font-weight-lighter text-white opacity-80"><FormattedMessage id="AUTH.GENERAL.INTRO" /></p>
          </div>
          <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
            <div className="opacity-70 font-weight-bold	text-white">&copy; {current_year} {appConfigs.org_name}</div>
            <div className="d-flex">
              <Link to="/privacy" className="text-white"><FormattedMessage id="AUTH.FOOTER.LINKS.PRIVACY" /></Link>
              <Link to="/contact" className="text-white ml-10"><FormattedMessage id="AUTH.FOOTER.LINKS.CONTACT" /></Link>
            </div>
          </div>
        </div>
      </div>
    : null
  }

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {getAsideTemplate()}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}
            {getHeaderBackLink()}
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute path="/auth/signup" component={Registration} />
                <ContentRoute path="/auth/forgot-password" component={ForgotPassword} />
                <ContentRoute path="/auth/reset-password/:token" component={ResetPassword} />
                <ContentRoute path="/auth/verify-email-request" component={VerifyEmail} />
                <ContentRoute path="/auth/verify-email/:token" component={VerifyEmail} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">&copy; {current_year} {appConfigs.org_name}</div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/privacy" className="text-dark-75 text-hover-primary"><FormattedMessage id="AUTH.FOOTER.LINKS.PRIVACY" /></Link>
                <Link to="/contact" className="text-dark-75 text-hover-primary ml-4"><FormattedMessage id="AUTH.FOOTER.LINKS.CONTACT" /></Link>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}

import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
// Reference: https://www.npmjs.com/package/react-google-recaptcha
import ReCAPTCHA from "react-google-recaptcha";
import { appConfigs } from "../../../../_social_justice/_helpers";

const initialValues = {
  fullname: "",
  email: "",
  password: "",
  acceptTerms: false,
};

function Registration(props) {
  const { intl } = props;
  const alert = props.history.location.state?.alert;
  const [loading, setLoading] = useState(false);
  const [recaptchaChecked, setRecaptcha] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    fullname: Yup.string()
      .min(3, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH", }, { name: 'Full name', min: '3' }))
      .max(100, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH", }, { name: 'Full name', max: '100' }))
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED", }, { name: 'Full name' })),
    email: Yup.string()
      .email("Wrong email format")
      .min(5, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH", }, { name: 'Email', min: '5' }))
      .max(250, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH", }, { name: 'Email', max: '250' }))
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED", }, { name: 'Email' })),
    password: Yup.string()
      .min(6, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH", }, { name: 'Password', min: '6' }))
      .max(50, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH", }, { name: 'Password', max: '50' }))
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED", }, { name: 'Password' })),
    acceptTerms: Yup.bool().required(intl.formatMessage({ id: "AUTH.VALIDATION.AGREEMENT_REQUIRED", },)),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues, 
    validationSchema: RegistrationSchema, 
    onSubmit: (values, { setStatus, setSubmitting }) => {

      var navigateTo = '';
      var navigateAlert = {};
      var names = values.fullname.split(" ");

      if (names.length < 2) {
        setStatus({ msg: intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED", }, { name: 'Full name' }), type: 'danger' });
        setSubmitting(false);
        return;
      }

      var firstname = names[0];
      var middlename = names[1];
      var lastname = names.length > 2 ? names[1] + " " + names[2] : names[1];

      setSubmitting(true);
      enableLoading();
      register(values.email, values.fullname, firstname, middlename, lastname, values.password)
        .then((resp) => {
          // console.log(resp.data);
          if (resp.data.result_code === 1) {
            if (!resp.data.is_exist) {
              // Register success
              navigateAlert = { msg: intl.formatMessage({ id: "AUTH.REGISTER.SUCCESS", }, { email: resp.data.data.email }), type: 'success' };
              // props.login(accessToken);
              navigateTo = "/auth/login";
            } else {
              if (resp.data.is_validated === 0) {
                navigateAlert = { msg: intl.formatMessage({ id: "AUTH.REGISTER.ACCOUNT_EXISTS_UNVERIFIED", }, { email: values.email }), type: 'danger' };
                navigateTo = "/auth/verify-email";
              } else {
                navigateAlert = { msg: intl.formatMessage({ id: "AUTH.REGISTER.ACCOUNT_EXISTS_VERIFIED", }, { email: values.email }), type: 'info' };
                navigateTo = "/auth/login";
              }
            }
          } else {
            setStatus({ msg: intl.formatMessage({ id: "AUTH.REGISTER.ERROR", }), type: 'danger' });
          }
          disableLoading();
          setSubmitting(false);
        }).catch(() => {
          setSubmitting(false);
          setStatus({ msg: intl.formatMessage({ id: "AUTH.REGISTER.ERROR", }), type: 'danger' });
          disableLoading();
        }).finally(() => {
          if (navigateTo !== '') props.history.push(navigateTo, {alert: navigateAlert});
        });
    },
  });

  function onRecaptchaChange(value) {
    // console.log("Captcha value:", value);
    (value) ? setRecaptcha(true) : setRecaptcha(false);
  }

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1"><FormattedMessage id="AUTH.REGISTER.TITLE" /></h3>
        <p className="text-muted font-weight-bold"><FormattedMessage id="AUTH.REGISTER.DESCR" /></p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status ? (
          <div className={`mb-10 alert alert-custom alert-light-${formik.status.type} alert-dismissible`}>
            <div className="alert-text font-weight-bold">{formik.status.msg}</div>
          </div>
        ) : alert ? (
          <div className={`mb-10 alert alert-custom alert-light-${alert.type} alert-dismissible`}>
            <div className="alert-text font-weight-bold">{alert.msg}</div>
          </div>
        ) : null}
        {/* end: Alert */}

        {/* begin: Fullname */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Your full name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "fullname"
            )}`}
            name="fullname"
            {...formik.getFieldProps("fullname")}
          />
          {formik.touched.fullname && formik.errors.fullname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.fullname}</div>
            </div>
          ) : null}
        </div>
        {/* end: Fullname */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="new-password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <span></span> &nbsp;&nbsp;
            I agree to the &nbsp;
            <Link
              to="/terms"
              target="_blank"
              className="mr-1"
              rel="noopener noreferrer"
            >
              Terms &amp; Conditions
            </Link>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center- mb-3">
          <ReCAPTCHA
            sitekey={appConfigs.recaptcha_site_key}
            onChange={onRecaptchaChange}
            onExpired={onRecaptchaChange}
            onErrored={onRecaptchaChange}
          />
        </div>
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms || 
              !recaptchaChecked
            }
            className="btn btn-primary btn-block font-weight-bold px-9 py-4 my-3 mx-4-"
          >
            <span>Sign Up</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          {/* <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" />
            </button>
          </Link> */}
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));

import {createSlice} from "@reduxjs/toolkit";

const initialQuestionAnswersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  questionAnswerForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const questionAnswersSlice = createSlice({
  name: "questionAnswers",
  initialState: initialQuestionAnswersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getQuestionAnswerById
    questionAnswerFetched: (state, action) => {
      state.actionsLoading = false;
      state.questionAnswerForEdit = action.payload.questionAnswerForEdit;
      state.error = null;
    },
    // findQuestionAnswers
    questionAnswersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createQuestionAnswer
    questionAnswerCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      if(action.payload.questionAnswer !== null){
        var usr = action.payload.questionAnswer;
        state.entities.push(usr);
      }
    },
    // updateQuestionAnswer
    questionAnswerUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.questionAnswer.id) {
          return action.payload.questionAnswer;
        }
        return entity;
      });
    },
    answerUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.questionAnswer.id) {
          return action.payload.questionAnswer;
        }
        return entity;
      });
    },
    // deleteQuestionAnswer
    questionAnswerDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteQuestionAnswers
    questionAnswersDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // questionAnswersUpdateState
    questionAnswersStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

export function TilesWidget11({
  className,
  baseColor = "primary",
  widgetHeight = "150px",
  value="9,000",
  label="New Transactions",
  svgIcon="media/svg/icons/Layout/Layout-4-blocks.svg"
}) {
  return (
    <>
      <div
        className={`card card-custom bg-${baseColor} ${className}`}
        style={{ height: widgetHeight }}
      >
        <div className="card-body">
          <div className="row">
            <div className="col-4">
              <span className="svg-icon svg-icon-6x svg-icon-white ml-n2">
                <SVG src={toAbsoluteUrl(svgIcon)} />
              </span>
            </div>
            <div className="col-8 text-right">
              <div className={`text-inverse-${baseColor} font-weight-bolder font-size-h1`}>
                {value}
              </div>
              <a
                href="#"
                className={`text-inverse-${baseColor} font-weight-bold font-size-lg mt-2`}
              >
                {label}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import {createSlice} from "@reduxjs/toolkit";

const initialTreeNurseryBanksState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  treeNurseryBankForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const treeNurseryBanksSlice = createSlice({
  name: "treeNurseryBanks",
  initialState: initialTreeNurseryBanksState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getTreeNurseryBankById
    treeNurseryBankFetched: (state, action) => {
      state.actionsLoading = false;
      state.treeNurseryBankForEdit = action.payload.treeNurseryBankForEdit;
      state.error = null;
    },
    // findTreeNurseryBanks
    treeNurseryBanksFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createTreeNurseryBank
    treeNurseryBankCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      if(action.payload.treeNurseryBank !== null){
        var usr = action.payload.treeNurseryBank;
        state.entities.push(usr);
      }
    },
    // updateTreeNurseryBank
    treeNurseryBankUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.treeNurseryBank.id) {
          return action.payload.treeNurseryBank;
        }
        return entity;
      });
    },
    // deleteTreeNurseryBank
    treeNurseryBankDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteTreeNurseryBanks
    treeNurseryBanksDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // treeNurseryBanksUpdateState
    treeNurseryBanksStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});

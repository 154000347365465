import {createSlice} from "@reduxjs/toolkit";

const initialReportedCasesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  reportedCaseForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const reportedCasesSlice = createSlice({
  name: "reportedCases",
  initialState: initialReportedCasesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getReportedCaseById
    reportedCaseFetched: (state, action) => {
      state.actionsLoading = false;
      state.reportedCaseForEdit = action.payload.reportedCaseForEdit;
      state.error = null;
    },
    // findReportedCases
    reportedCasesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createReportedCase
    reportedCaseCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      if(action.payload.reportedCase !== null){
        var usr = action.payload.reportedCase;
        state.entities.push(usr);
      }
    },
    // updateReportedCase
    reportedCaseUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.reportedCase.id) {
          return action.payload.reportedCase;
        }
        return entity;
      });
    },
    //reportedCaseAnswered
    reportedCaseAnswered: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.reportedCase.id) {
          return action.payload.reportedCase;
        }
        return entity;
      });
    },
    // deleteReportedCase
    reportedCaseDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteReportedCases
    reportedCasesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // reportedCasesUpdateState
    reportedCasesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});

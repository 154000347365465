import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { rolesSlice } from "../app/modules/UserManagement/_redux/roles/rolesSlice";
import { usersSlice } from "../app/modules/UserManagement/_redux/users/usersSlice";
import { hrdMaterialsSlice } from "../app/modules/Settings/_redux/hrdMaterials/hrdMaterialsSlice";
import { lawMaterialsSlice } from "../app/modules/Settings/_redux/lawMaterials/lawMaterialsSlice";
import { questionAnswersSlice } from "../app/modules/Settings/_redux/questionAnswers/questionAnswersSlice";
import { rapidResponsesSlice } from "../app/modules/Settings/_redux/rapidResponses/rapidResponsesSlice";
import { reportedCasesSlice } from "../app/modules/Settings/_redux/reportedCases/reportedCasesSlice";
import { naturalJusticeMaterialsSlice } from "../app/modules/Settings/_redux/naturalJusticeMaterials/naturalJusticeMaterialsSlice";
import { legalQuestionsSlice } from "../app/modules/Settings/_redux/legalQuestions/legalQuestionsSlice";
import { periodBanksSlice } from "../app/modules/Settings/_redux/periodBanks/periodBanksSlice";
import { treeNurseryBanksSlice } from "../app/modules/Settings/_redux/treeNurseryBanks/treeNurseryBanksSlice";
import { socialEconomicRightsSlice } from "../app/modules/Settings/_redux/socialEconomicRights/socialEconomicRightsSlice";
import { justiceCentresSlice } from "../app/modules/Settings/_redux/justiceCentres/justiceCentresSlice";
import { settingsSlice }from "../app/modules/Settings/_redux/system/settingsSlice";



export const rootReducer = combineReducers({
  auth: auth.reducer,
  roles: rolesSlice.reducer,
  users: usersSlice.reducer,
  settings: settingsSlice.reducer,
  hrdMaterials: hrdMaterialsSlice.reducer,
  lawMaterials: lawMaterialsSlice.reducer,
  questionAnswers: questionAnswersSlice.reducer,
  rapidResponses: rapidResponsesSlice.reducer,
  reportedCases: reportedCasesSlice.reducer,
  naturalJusticeMaterials: naturalJusticeMaterialsSlice.reducer,
  legalQuestions: legalQuestionsSlice.reducer,
  periodBanks: periodBanksSlice.reducer,
  socialEconomicRights: socialEconomicRightsSlice.reducer,
  justiceCentres: justiceCentresSlice.reducer,
  treeNurseryBanks: treeNurseryBanksSlice.reducer,

});

export function* rootSaga() {
  yield all([auth.saga()]);
}

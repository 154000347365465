import axios from "axios";
import { appConfigs } from "../../../../../_social_justice/_helpers";

// CREATE =>  POST: add a new legalQuestion to the server
export function createLegalQuestion(legalQuestion) {
  return axios.post(appConfigs.api_url + 'legal-questions/create', { legalQuestion });
}

// READ
export function getAllLegalQuestions() {
  return axios.post(appConfigs.api_url + 'legal-questions');
}

export function getLegalQuestionById(legalQuestionId) {
  return axios.post(`${appConfigs.api_url}legal-questions/get`, { id: legalQuestionId })
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findLegalQuestions(queryParams) {
  return axios.post(`${appConfigs.api_url}legal-questions`, { queryParams });
}

// UPDATE => POST: update the legalQuestion on the server
export function updateLegalQuestion(legalQuestion) {
  return axios.post(`${appConfigs.api_url}legal-questions/update`, { legalQuestion });
}

// Answer => POST: update the legalQuestion on the server
export function answerLegalQuestion(legalQuestion) {
  return axios.post(`${appConfigs.api_url}legal-questions/answer`, { legalQuestion });
}

// UPDATE Status
export function updateStatusForLegalQuestions(ids, status) {
  return axios.post(`${appConfigs.api_url}/updateStatusForLegalQuestions`, {
    ids,
    status
  });
}

// DELETE => delete the legalQuestion from the server
export function deleteLegalQuestion(legalQuestionId) {
  return axios.post(`${appConfigs.api_url}legal-questions/delete`, { id: legalQuestionId });
}

// DELETE LegalQuestions by ids
export function deleteLegalQuestions(ids) {
  return axios.post(`${appConfigs.api_url}legal-questions/delete-legal-questions`, { legalQuestion_ids: ids });
}

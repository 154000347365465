import {createSlice} from "@reduxjs/toolkit";

const initialPeriodBanksState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  periodBankForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const periodBanksSlice = createSlice({
  name: "periodBanks",
  initialState: initialPeriodBanksState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getPeriodBankById
    periodBankFetched: (state, action) => {
      state.actionsLoading = false;
      state.periodBankForEdit = action.payload.periodBankForEdit;
      state.error = null;
    },
    // findPeriodBanks
    periodBanksFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createPeriodBank
    periodBankCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      if(action.payload.periodBank !== null){
        var usr = action.payload.periodBank;
        state.entities.push(usr);
      }
    },
    // updatePeriodBank
    periodBankUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.periodBank.id) {
          return action.payload.periodBank;
        }
        return entity;
      });
    },
    // deletePeriodBank
    periodBankDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deletePeriodBanks
    periodBanksDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // periodBanksUpdateState
    periodBanksStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
